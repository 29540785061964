// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeTopic from 'components/Contentful/KnowledgeTopic'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Fördergelder für Wärmepumpen"
        meta={[
          { name: 'description', content: 'Alle Fördergelder für Wärmepumpen in Ihrem Kanton auf einen Blick. Jetzt ✔️ Zuschuss checken ✔️ Infos einholen ✔️ loslegen!' },
          { name: 'keywords', content: 'Fördergelder Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='7HWpzzFE2QC7stg9Rsx3B4-254Kmn8ZMdFzjdPgwK6kZ8'
          id=''
        >
          <KnowledgeTopic
            {...{
    "type": "knowledgeTopic",
    "id": "254Kmn8ZMdFzjdPgwK6kZ8",
    "updatedAt": "2024-08-21T10:07:59.470Z",
    "title": "Wärmepumpen werden in der Schweiz grosszügig mit Fördergeldern unterstützt und wir helfen Ihnen dabei diese zu erhalten.",
    "featuredArticles": {
        "type": "knowledgeHighlight",
        "id": "3Fxbb54RgN1tUa7h7ZldnB",
        "updatedAt": "2023-04-20T14:54:34.447Z",
        "title": "Artikel zum Thema Fördergelder Wärmepumpe",
        "knowledgeArticles": [
            {
                "type": "page",
                "id": "1zgjiLjz3qEPMjsgOk2B60",
                "updatedAt": "2022-08-08T11:38:35.920Z",
                "name": "Schweizweite Förderung myclimate",
                "slug": "foerdergelder/schweizweite-foerderung",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "3MG0ZEvFi5F8v7Aep05uds",
                        "updatedAt": "2023-04-20T16:10:27.182Z",
                        "title": "Schweizweite Förderung myclimate",
                        "keyword": "Schweizweite Förderung",
                        "articleType": "Infoartikel",
                        "description": "Die Stiftung myclimate unterstützt Sie mit rund CHF 3'800, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "RDHM7UzeP0UbcPZO7PemF",
                                "updatedAt": "2021-11-11T08:37:31.475Z",
                                "title": "myclimate",
                                "text": "<p>Unabhängig von den kantonalen Förderstellen, unterstützt die Stiftung myclimate Bauherrschaften, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen. <strong>myclimate erhöht ihre Förderbeiträge auf 0.18 CHF/kWh</strong> basierend auf dem jährlichen Energieverbrauch und beträgt <strong>mindestens CHF 2'000</strong>. Der Förderbeitrag erhöht sich somit auf <strong>rund CHF 3'800</strong> für Wärmepumpen bis 15 kW.</p>\n<p>Es werden nur Wärmepumpen gefördert, die zum Zeitpunkt der Anmeldung keine anderen Fördergelder von Kanton oder Gemeinde beziehen können.</p>\n<h3>Förderbeitrag</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>Ø CHF 3'800</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>Ø CHF 3'800</td>\n</tr>\n</tbody>\n</table>\n<h3>Förderstelle</h3>\n<p>myclimate<br>\nPfingstweidstrasse 10<br>\n8005 Zürich<br>\nTel: 044 500 43 50<br>\n<a href=\"https://www.myclimate.org/de/informieren/klimaschutzprojekte/detail-klimaschutzprojekte/schweiz-energieeffizienz-7816/\">Website<br>\n</a></p>\n",
                                "gallery": [
                                    {
                                        "type": "youTubeLink",
                                        "id": "501asswDZBxK8SoslcT1ws",
                                        "updatedAt": "2020-03-20T10:31:06.030Z",
                                        "title": "myclimate - Klimaschutz lokal und global",
                                        "link": "https://youtu.be/8YGN23ULs_o",
                                        "description": "<p>Die Stiftung myclimate stellt sich vor.</p>\n",
                                        "videoId": "8YGN23ULs_o",
                                        "start": null
                                    }
                                ]
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Logo farbig myclimate shape our future",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/3QFNO7y5VfxkijsSNc0nlP/0a08a3360312ba1ed64815967168e16e/1200px-Myclimate_201x_logo.svg.png"
                        }
                    }
                ],
                "title": "Wärmepumpen-Förderbeitrag von myclimate",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen und rund CHF 3.800 kassieren ✔️ myclimate Förderprogramm ✔️ Hier informieren ✔️",
                "metaKeywords": "Förderbeitrag Wärmepumpe"
            },
            {
                "type": "page",
                "id": "KB1G0tadoHm5orNSbTRd0",
                "updatedAt": "2022-08-05T10:28:03.352Z",
                "name": "Häufig gestellte Fragen zum Thema Fördergelder Wärmepumpe",
                "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "5WqxEVV7eVFkRDXhml4Vhe",
                        "updatedAt": "2023-04-20T16:09:56.043Z",
                        "title": "Häufig gestellte Fragen zum Thema Fördergelder Wärmepumpe",
                        "keyword": "Häufig gestellte Fragen",
                        "articleType": "FAQ",
                        "description": "Alles, was Sie schon immer über über das Thema Fördergelder Wärmepumpe in der Schweiz wissen wollten!",
                        "content": [
                            {
                                "type": "FAQ",
                                "id": "bdWk3vG8Qh5ijmV5hP3CK",
                                "updatedAt": "2020-03-20T12:10:27.437Z",
                                "title": "Förderlandschaft Schweiz",
                                "question": "<p>Wie funktioniert die Förderlandschaft in der Schweiz?</p>\n",
                                "answer": "<p>Aus einem Grundsatzentscheid des Bundes für einen schrittweisen Ausstieg aus der Kernenergie sind diverse Förderprogramme entstanden, sodass mittlerweile oft von einem “Förderdschungel” die Rede ist. Wir bringen Licht ins Dunkel.</p>\n<p>Grundsätzlich sind die Förderprogramme Angelegenheit der Kantone. Der Bund leistet Globalbeiträge an die Förderprogramme der Kantone. Das wichtigste Instrument in diesem Bereich ist das <a href=\"https://www.dasgebaeudeprogramm.ch/de/\" title=\"www.dasgebaeudeprogramm.ch\">Gebäudeprogramm</a>. Es unterstützt in der ganzen Schweiz die Verbesserung der Wärmedämmung von Einfamilienhäusern, Mehrfamilienhäusern oder Dienstleistungsgebäude. Gemeinden können ergänzend zu den Förderprogrammen der Kantone weitere Fördergelder ausschütten.</p>\n<p>Unabhängig von den kantonalen Förderstellen, unterstützt die Stiftung myClimate Bauherrschaften mit 2’000 Franken, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen. Es werden nur Wärmepumpen gefördert, die zum Zeitpunkt der Anmeldung keine anderen Fördergelder von Kanton oder Gemeinde beziehen können.</p>\n<p>Wollen Sie herausfinden, welche Förderbeiträge Ihnen an Ihrem Wohnsitz zur Verfügung stehen? Bei <a href=\"https://www.energiefranken.ch\" title=\"www.energiefranken.ch\">Energiefranken</a> können Sie dies unkompliziert abfragen.</p>\n"
                            },
                            {
                                "type": "FAQ",
                                "id": "5ekhZrdmrBxd4wpQGIld9X",
                                "updatedAt": "2020-03-20T12:10:55.775Z",
                                "title": "Wie erhalte ich meine Fördergelder?",
                                "question": "<p>Wie erhalte ich meine Fördergelder?</p>\n",
                                "answer": "<p>Abhängig von Ihrer bestehenden Heizung, von Ihrem Wohnort, von Ihrer neuen Heizung und weiteren Kriterien, ist das Vorgehen unterschiedlich. Auf unserer Website finden Sie eine Übersicht pro Kanton. Das Vorgehen variert pro Kanton respektive Förderstelle.</p>\n<p>Gerne kümmern wir uns um Ihre Fördergelder. Lassen Sie sich am besten noch heute beraten und realisieren Sie Ihr Umbauprojekt mit uns!</p>\n<p>Wir erstellen in der Regel die nötigen Dokumente für den Erhalt der\nFördergelder. Die Fördergelder werden ihnen nach Projektabschluss direkt\nausbezahlt.</p>\n<p><em>Wichtiger Hinweis:</em>\nBei gewissen Förderprogrammen ist es nötig, diese vor\nUmbaubeginn oder sogar vor Auftragsvergabe einzureichen. Bitte sprechen Sie\nsich mit unserem Berater diesbezüglich ab.</p>\n"
                            },
                            {
                                "type": "FAQ",
                                "id": "2OGgO7e7SfZXfwkle4rSAM",
                                "updatedAt": "2020-03-20T12:11:15.365Z",
                                "title": "Beantragungsstelle Fördergelder",
                                "question": "<p>Wo beantrage ich die Fördergelder für meinen Heizungsersatz?</p>\n",
                                "answer": "<p>Abhängig von Ihrer bestehenden Heizung, von Ihrem Wohnort, von Ihrer neuen Heizung und weiteren Kriterien, kann die Förderstelle varieren. Auf unserer Website finden Sie eine Übersicht pro Kanton.</p>\n<p>Gerne kümmern wir uns um Ihre Fördergelder. Lassen Sie sich am besten noch heute beraten und realisieren Sie Ihr Umbauprojekt mit uns!</p>\n<p>Wir erstellen in der Regel die nötigen Dokumente für den Erhalt der\nFördergelder. Die Fördergelder werden ihnen nach Projektabschluss direkt\nausbezahlt.</p>\n<p><em>Wichtiger Hinweis:</em>\nBei gewissen Förderprogrammen ist es nötig, diese vor\nUmbaubeginn oder sogar vor Auftragsvergabe einzureichen. Bitte sprechen Sie\nsich mit unserem Berater diesbezüglich ab.</p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Heizungsmacher - häufig gestellte Fragen",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/35SWWHYijCZ09BkUucaY7N/a83c972b34abd14bdf280e92dd70f1d8/faq-placeholder-img.png"
                        }
                    }
                ],
                "title": "FAQ zu Fördergelder Wärmepumpe",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Fördergelder für eine Wärmepumpe: Wichtigste Fragen ➤ Förderlandschaft ✔️ Richtige Beantragung ✔️ Vorgehen pro Kanton ✔️ Jetzt hier informieren!"
            },
            {
                "type": "page",
                "id": "G7kEm9LsEk2SzJwcTikub",
                "updatedAt": "2022-08-08T12:27:57.181Z",
                "name": "Fördergelder Kanton Aargau",
                "slug": "foerdergelder/kanton-aargau",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "MFcfL6FgzFFzLURkqjaEO",
                        "updatedAt": "2023-04-20T16:05:58.450Z",
                        "title": "Fördergelder Kanton Aargau",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Aargau profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "etXcd4c0tb7tdObNdIi2o",
                                "updatedAt": "2024-10-15T05:09:11.624Z",
                                "title": "Fördergelder Kanton Aargau",
                                "text": "<p>Fördergelder Kanton Aargau</p>\n<p>Der Kanton unterstützt Bauherrschaften mit einem Grundbeitrag von <strong>1’600 Franken (Luft-/Wasser)</strong> respektive <strong>6’000 Franken (Sole-/Wasser)</strong>, wenn Sie Ihre alte Heizöl-, Erdgas- oder Elektroheizung ersetzen. Hinzu kommen leistungsabhängig Beiträge sowie die Kostenübernahme für die Erstellung des Wärmepumpen-System-Modul (WPSM) Zertifikates.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 1'600 + CHF 60/kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 6'000 + CHF 180/kWth</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter auf der <a href=\"https://www.ag.ch/de/verwaltung/bvu/energie/foerderungen\" title=\"Förderprogramm Kanton Aargau\">Webseite des Kanton Aargau</a>.</p>\n<h3>Förderstelle</h3>\n<p>Departement Bau, Verkehr und Umwelt<br>\nAbteilung Energie<br>\nEntfelderstrasse 22<br>\n5001 Aarau<br>\nTel.: 062 835 45 40<br>\n<a href=\"https://www.ag.ch/de/verwaltung/bvu/ueber-uns/organisation/abteilung-energie\" title=\"Abteilung Energie - Kanton Aargau\">Webseite</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Aargau",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/2xSfaIy3WrkTE5Cigmh3uZ/f8b283d0d00fc25ae730224fe45c782c/Aargau.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Fördergelder im Aargau",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Fördergeldern im Kanton Aargau profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔",
                "metaKeywords": "Fördergelder Kanton Aargau"
            },
            {
                "type": "page",
                "id": "4mdCZe50D4tAoW4K7xb9I7",
                "updatedAt": "2022-08-08T12:45:26.822Z",
                "name": "Fördergelder Kanton Basel-Landschaft",
                "slug": "foerdergelder/kanton-basel-landschaft",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "e1JYk0vi5tWQKCy7ontdP",
                        "updatedAt": "2023-04-20T16:07:13.369Z",
                        "title": "Fördergelder Kanton Basel-Landschaft",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Basel-Landschaft profitieren Sie vom kantonalen Förderprogramm. So erhalten Sie einen individuellen Beitrag je nach Heizungsersatz.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "3MNJnd49F2Vhj4xNmZKXuw",
                                "updatedAt": "2024-02-28T13:24:45.915Z",
                                "title": "Fördergelder Kanton Basel-Landschaft",
                                "text": "<h3>Fördergelder Kanton Basel-Landschaft</h3>\n<p>Der Kanton unterstützt Bauherrschaften mit einem Grundbeitrag von <strong>7’000 Franken (Luft-/Wasser)</strong> respektive <strong>10’000 Franken (Sole-/Wasser)</strong>, wenn Sie Ihre alte Heizöl-, Erdgas- oder Elektroheizung ersetzen. Hinzu kommen leistungsabhängig Beiträge.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 7'000 + CHF 100/kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 10'000 + CHF 200/kWth</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie auf der Seite des <a href=\"https://www.energiepaket-bl.ch/foerdermassnahmen/heizung-warmwasser/waermepumpe\" title=\"Baselbieter Energiepaket\">Baselbieter Energiepaketes</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Basel-Landschaft<br>\nBau- und Umweltschutzdirektion<br>\nRheinstrasse 29<br>\n4410 Liestal<br>\nTel: 061 552 55 55<br>\n<a href=\"https://www.energiepaket-bl.ch/\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Basel-Landschaft",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/73BI3MfLaF4VCPmhhFug9j/6e8e2e5f72c05339a152ed615e66c543/Basel_Land.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Fördergelder im Baselland",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Fördergeldern im Kanton Basel-Landschaft profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔",
                "metaKeywords": "Fördergelder Kanton Basel-Landschaft"
            },
            {
                "type": "page",
                "id": "4IcMqgAzqiSNIOjjqqnSHI",
                "updatedAt": "2022-08-08T12:44:21.581Z",
                "name": "Fördergelder Kanton Basel-Stadt",
                "slug": "foerdergelder/kanton-basel-stadt",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "6lxsZ8F18JKnlAhlsA8T7z",
                        "updatedAt": "2023-04-20T16:08:34.933Z",
                        "title": "Fördergelder Kanton Basel-Stadt",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Basel-Stadt profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "3fpRmxi5kJqL64zwOgfVPS",
                                "updatedAt": "2023-04-20T14:18:14.467Z",
                                "title": "Fördergelder Kanton Basel-Stadt",
                                "text": "<h3>Fördergelder Kanton Basel-Stadt</h3>\n<p>Luft/Wasser-, Sole/Wasser- und Wasser/Wasser-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Hierbei sind die Anforderungen, Nebenbedingungen und Dimensionierungsgrundlagen gemäss Energieverordnung zu beachten resp. einzuhalten.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n<th>Bemerkung</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 8'000 + CHF 250/kWth</td>\n<td>-</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 30'000 pauschal</td>\n<td>bis 10 kWth</td>\n</tr>\n</tbody>\n</table>\n<p><strong>Die obere Limite für Förderbeiträge vom Kanton Basel-Stadt beträgt maximal 40% der gesamten Investitionskosten für Energieeffizienzmassnahmen. Diese Limite gilt auch für Wärmepumpen.</strong></p>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://www.aue.bs.ch/energie/foerderbeitraege/waermepumpen.html\">Link</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Basel-Stadt<br>\nEnergiefachstelle<br>\nHochbergerstrasse 158<br>\n4019 Basel<br>\nTel: 061 639 23 50<br>\n<a href=\"https://www.aue.bs.ch/energie/foerderbeitraege.html\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Basel-Stadt",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/4I1oj4cYAtZk01QXbF6tqn/262e1906dd77f93adcb6765b08d5516e/Basel_Stadt.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Förderbeiträge in Basel-Stadt",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Förderbeiträgen in Basel-Stadt profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️",
                "metaKeywords": "Fördergelder Kanton Basel-Stadt"
            },
            {
                "type": "page",
                "id": "1onQZor8sCne7t0566aCRZ",
                "updatedAt": "2022-08-08T12:42:32.493Z",
                "name": "Fördergelder Kanton Bern",
                "slug": "foerdergelder/kanton-bern",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "2cfxdQoTMrp2KDuiGdZRig",
                        "updatedAt": "2023-04-20T16:08:34.904Z",
                        "title": "Fördergelder Kanton Bern",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Bern profitieren Sie vom kantonalen Förderprogramm. So erhalten Sie einen Pauschalbeitrag an Ihren Heizungsersatz.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "4RE1oZKp0pF07st4DdUVpb",
                                "updatedAt": "2023-04-20T14:20:13.304Z",
                                "title": "Fördergelder Kanton Bern",
                                "text": "<h3>Fördergelder Kanton Bern</h3>\n<p>Der Kanton unterstützt Bauherrschaften mit bis zu <strong>10’000 Franken</strong>, wenn Sie Ihre alte Öl-, Gas oder Elektroheizung durch eine Wärmepumpe ersetzen.</p>\n<h3>Förderbeiträge</h3>\n<p>Ersatz von Elektroheizungen durch Wärmepumpe</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n<th>Bemerkung</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 4'500</td>\n<td>bestehende Heizung &#x3C; 20 kW</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 6'000</td>\n<td>bestehende Heizleistung &#x3C; 20 kW</td>\n</tr>\n</tbody>\n</table>\n<p>Ersatz von Öl- und Gasheizungen durch Wärmepumpe</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n<th>Bemerkung</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 6'000</td>\n<td>bestehende Heizung &#x3C; 50kW</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 10'000</td>\n<td>bestehende Heizleistung &#x3C; 42 kW</td>\n</tr>\n</tbody>\n</table>\n<h3>Förderstelle</h3>\n<p>Kanton Bern<br>\nWirtschafts-, Energie- und Umweltdirektion<br>\nAmt für Umwelt und Energie<br>\nReiterstrasse 11<br>\n3011 Bern<br>\nTel: 031 633 36 50<br>\n<a href=\"https://www.vol.be.ch/vol/de/index/energie/energie.html\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Bern",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/UTohe2gtfk3RzS1hqQ91j/d761786904e4463436896ed51aeb4c53/Bern.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Förderbeiträge in Bern",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Förderbeiträgen im Kanton Bern profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️",
                "metaKeywords": "Fördergelder Kanton Bern"
            },
            {
                "type": "page",
                "id": "1sT2GA8nURB9TrUleBohg6",
                "updatedAt": "2022-08-08T12:43:55.303Z",
                "name": "Fördergelder Kanton Luzern",
                "slug": "foerdergelder/kanton-luzern",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "1bDmG9Hy7PcpzLZQAzF92l",
                        "updatedAt": "2023-04-20T16:08:34.863Z",
                        "title": "Fördergelder Kanton Luzern",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Luzern profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "4TFHw96vSa3DO93gfysfHe",
                                "updatedAt": "2023-04-20T15:25:55.732Z",
                                "title": "Fördergelder Kanton Luzern",
                                "text": "<h3>Fördergelder Luzern</h3>\n<p>Der Kanton unterstützt Bauherrschaften mit einem Grundbeitrag von <strong>4'000 Franken (Luft-/Wasser)</strong> respektive <strong>8'500 Franken (Sole-/Wasser)</strong>, wenn Sie Ihre alte Heizöl-, Erdgas- oder Elektroheizung ersetzen. Hinzu kommen leistungsabhängig Beiträge.</p>\n<h3>Förderbeiträge</h3>\n<p>Beiträge bis 15 kW installierter thermischer Nennleistung</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 4'000</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 8'500</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://uwe.lu.ch/themen/energie/foerderprogramme/waermepumpen\">Link</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Luzern<br>\nUmweltberatung Luzern<br>\nLöwenplatz 11<br>\n6004 Luzern<br>\nTel: 041 412 32 32<br>\n<a href=\"https://uwe.lu.ch/\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Luzern",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/2YiMBjTHpYiVJ0i05OAsVT/492ed5b38cd91acc5a916adaab1aa0eb/Luzern.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Fördergelder im Kanton Luzern",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Förderbeiträgen im Kanton Luzern profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️",
                "metaKeywords": "Fördergelder Kanton Luzern"
            },
            {
                "type": "page",
                "id": "3sCJcJL37OFyBBQcBCkoe6",
                "updatedAt": "2022-08-09T08:57:35.110Z",
                "name": "Fördergelder Kanton Solothurn",
                "slug": "foerdergelder/kanton-solothurn",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "5pWlv5PaqxjD5SEXfH2dVV",
                        "updatedAt": "2023-04-20T16:08:12.963Z",
                        "title": "Fördergelder Kanton Solothurn",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Solothurn profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "2OofTQyUMwt48KeQG047Rd",
                                "updatedAt": "2023-04-28T08:35:33.465Z",
                                "title": "Fördergelder Kanton Solothurn",
                                "text": "<h3>Fördergelder Kanton Solothurn</h3>\n<p>Der Kanton unterstützt Bauherrschaften mit einem Grundbeitrag von <strong>4’000 Franken (Luft-/Wasser)</strong> respektive <strong>6’000 Franken (Sole-/Wasser)</strong>, wenn Sie Ihre alte Heizöl-, Erdgas- oder Elektroheizung ersetzen. Hinzu kommen leistungsabhängig Beiträge.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 4'000 + CHF 150/kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 6'000 + CHF 450/kWth</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://energie.so.ch/foerderung/foerdermassnahmen\" title=\"Link Fördermassnahmen Kanton Solothurn\">Link</a>.</p>\n<h3>Förderstelle</h3>\n<p>Kanton Solothurn<br>\nEnergiefachstelle<br>\nRathausgasse 16<br>\n4509 Solothurn<br>\nTel: 032 627 85 24<br>\n<a href=\"http://so.ch/verwaltung/volkswirtschaftsdepartement/amt-fuer-wirtschaft-und-arbeit/energiefachstelle/\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Solothurn",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/31RHpzseD5iPla7Lf7yvh1/74bd2a2b29ce15aa6d81bef12019e5d7/Solothurn.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Förderbeiträge im Kanton Solothurn",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Förderbeiträgen im Kanton Solothurn profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔️",
                "metaKeywords": "förderbeiträge kanton solothurn"
            },
            {
                "type": "page",
                "id": "cw5pJDnq7BuDoxHULqwTp",
                "updatedAt": "2023-09-28T14:57:43.487Z",
                "name": "Fördergelder Kanton St. Gallen",
                "slug": "foerdergelder/foerdergelder-kanton-st-gallen",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "yY4voQRrY6Gk24iYC86VU",
                        "updatedAt": "2023-04-20T14:54:14.251Z",
                        "title": "Fördergelder Kanton St. Gallen",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton St. Gallen profitieren Sie vom kantonalen Förderprogramm. So erhalten Sie einen individuellen Beitrag je nach Heizungsersatz.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "60tylZzjsG0bF5xnw2QuTM",
                                "updatedAt": "2023-04-20T14:46:14.828Z",
                                "title": "Fördergelder Kanton St. Gallen",
                                "text": "<h3>Fördergelder Kanton St. Gallen</h3>\n<p>Luft/Wasser-, Sole/Wasser- und Wasser/Wasser-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Hierbei sind die Anforderungen, Nebenbedingungen und Dimensionierungsgrundlagen gemäss Förderprogramm Energie des Kantons zu beachten resp. einzuhalten.</p>\n<h3>Förderbeiträge</h3>\n<p>Beiträge bis 20 kW installierter thermischer Nennleistung</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 2'800</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 6'000</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://www.energieagentur-sg.ch/waermepumpe\">Link</a>.</p>\n<h3><strong>Förderstelle</strong></h3>\n<p>Kanton St. Gallen<br>\nEnergieagentur St.Gallen GmbH<br>\nVadianstrasse 6<br>\n9000 St. Gallen<br>\nTel: 058 228 71 88<br>\n<a href=\"https://www.energieagentur-sg.ch/\">Webseite</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton St. Gallen",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/6N7urcNaTOgeJTGvac3TE9/5cc881d43b5ee22c6062496914a3c160/St._Gallen.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Förderbeiträge in St.Gallen",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "metaDescription": "Von Förderbeiträgen im Kanton St. Gallen profitieren. Kantonales Förderprogramm ✔️ Grundbeiträge bis 20 kW installierter Leistung ✔️ Hier informieren ✔️",
                "metaKeywords": "förderbeiträge kanton st. gallen"
            },
            {
                "type": "page",
                "id": "56rCK7sxrjjkmZbiTTDxmF",
                "updatedAt": "2023-09-28T14:57:33.053Z",
                "name": "Fördergelder Kanton Thurgau",
                "slug": "foerdergelder/kanton-thurgau",
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "aphHyW5PTaiNgTs450fnV",
                        "updatedAt": "2023-04-20T14:52:08.805Z",
                        "title": "Fördergelder Kanton Thurgau",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Thurgau profitieren Sie vom kantonalen Förderprogramm. So erhalten Sie einen individuellen Beitrag je nach Heizungsersatz.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "3IIfzxPI4JTb4UGrCERYVJ",
                                "updatedAt": "2023-04-20T15:24:13.809Z",
                                "title": "Fördergelder Kanton Thurgau",
                                "text": "<h3>Fördergelder Kanton Thurgau</h3>\n<p>Luft/Wasser-, Sole/Wasser- und Wasser/Wasser-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Die neu installierte Wärmepumpe muss als Hauptheizung eingesetzt werden.</p>\n<h3>Förderbeiträge</h3>\n<p>Beiträge bis 15 kW installierter thermischer Nennleistung</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 3'000</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 9'000</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://energie.tg.ch/hauptrubrik-2/formulare-projektabschluss.html/860\">Link</a>.</p>\n<h3><strong>Förderstelle</strong></h3>\n<p>Kanton Thurgau<br>\nAbteilung Energie<br>\nPromenadenstrasse 8<br>\n8510 Frauenfeld<br>\nTel: 058 345 54 80<br>\n<a href=\"https://energie.tg.ch/hauptrubrik-2/formulare-projektabschluss.html/860\">Webseite</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Thurgau",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/3y0DpSmvJbPs4NF1K9DNOR/6152ee04b447a1254de8de3438f8b183/Flag_of_Canton_of_Thurgau.svg.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Förderbeiträge im Thurgau",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "metaDescription": "Von Förderbeiträgen im Kanton Thurgau profitieren. Kantonales Förderprogramm ✔️ Grundbeiträge bis 15 kW installierter Leistung ✔️ Hier informieren ✔️",
                "metaKeywords": "förderbeiträge kanton thurgau"
            },
            {
                "type": "page",
                "id": "63AT6t8kUgtPNmfDW1gFJ4",
                "updatedAt": "2022-08-09T08:59:40.730Z",
                "name": "Fördergelder Kanton Zug",
                "slug": "foerdergelder/kanton-zug",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "73q0EVOTd5DA2GtWe16Pi8",
                        "updatedAt": "2023-04-20T16:06:29.638Z",
                        "title": "Fördergelder Kanton Zug",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Zug profitieren Sie vom kantonalen Förderprogramm. Sie erhalten einen Grundbeitrag plus leistungsabhängige Beiträge für Erdsonden.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "3megwG4Y3aTyQKGZgliEo7",
                                "updatedAt": "2024-04-17T13:07:50.326Z",
                                "title": "Fördergelder Kanton Zug",
                                "text": "<h3>Fördergelder Kanton Zug</h3>\n<p>Luft/Wasser- und Sole/Wasser (Erdsonden)-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Hierbei sind die Anforderungen, Nebenbedingungen und Dimensionierungsgrundlagen gemäss Förderprogramm Energie des Kantons zu beachten resp. einzuhalten.</p>\n<h3>Förderbeiträge</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n<th>Bemerkung</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 8'500</td>\n<td>bis 30 kWth</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 20'000 + CHF 400/kWth</td>\n<td>bis 500 kWth</td>\n</tr>\n</tbody>\n</table>\n<h3><strong>Förderstelle</strong></h3>\n<p>Kanton Zug<br>\nEnergiefachstelle, Amt für Umwelt<br>\nPostfach<br>\n6301 Zug<br>\nTel: 041 728 53 70<br>\n<a href=\"https://www.zg.ch/behoerden/baudirektion/energiefachstelle/gebaeudeprogramm/heizungsersatz\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpe Kanton Zug",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/tpVI5qffWoOTIRwcSdTeP/3b0fddc566aaf9dad1ebff26fc625f0a/Zug.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Fördergelder im Kanton Zug",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Fördergeldern im Kanton Zug profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔",
                "metaKeywords": "fördergelder kanton zug"
            },
            {
                "type": "page",
                "id": "5vpcuy4dK7PYaCVhoBD4Hx",
                "updatedAt": "2022-08-09T09:02:36.146Z",
                "name": "Fördergelder Kanton Zürich",
                "slug": "foerdergelder/kanton-zuerich",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "1hNzhEgmwInOaL8v07x0tx",
                        "updatedAt": "2023-04-20T16:07:01.750Z",
                        "title": "Fördergelder Kanton Zürich",
                        "keyword": "Fördergelder",
                        "articleType": "Infoartikel",
                        "description": "Im Kanton Zürich profitieren Sie vom kantonalen Förderprogramm. So erhalten Sie einen individuellen Beitrag je nach Heizungsersatz.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "7I0gTJ9jAiOGRHqMU7udHE",
                                "updatedAt": "2023-08-17T07:25:36.240Z",
                                "title": "Fördergelder Kanton Zürich",
                                "text": "<h3>Fördergelder Kanton Zürich</h3>\n<p>Luft/Wasser-, Sole/Wasser- und Wasser/Wasser-Wärmepumpen, die als Hauptheizung eingesetzt werden und eine Öl-, Gas- oder Elektroheizung ersetzen, sind förderbeitragsberechtigt. Hierbei sind die Anforderungen, Nebenbedingungen und Dimensionierungsgrundlagen gemäss Förderprogramm Energie des Kantons zu beachten resp. einzuhalten.</p>\n<h3>Förderbeiträge</h3>\n<p>Beiträge bis 15 kW installierter thermischer Nennleistung</p>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>CHF 4'650</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>CHF 10'650</td>\n</tr>\n</tbody>\n</table>\n<p>Weitere Informationen finden Sie unter folgendem <a href=\"https://www.zh.ch/de/umwelt-tiere/energie/energiefoerderung.html\" title=\"Energieförderung und Beratung Kt. ZH\">Link</a>.</p>\n<p>Zusätzliche Förderung der Stadt Zürich:<br>\nErfahren Sie mehr über die <a href=\"https://www.heizungsmacher.ch/wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz/\">Restwertentschädigung bei vorzeitigem Heizungsersatz</a>.</p>\n<h3><strong>Förderstelle</strong></h3>\n<p>Kanton Zürich Baudirektion<br>\nAmt für Abfall, Wasser, Energie und Luft<br>\nStampfenbachstrasse 12<br>\n8090 Zürich<br>\nTel: 043 259 42 66<br>\n<a href=\"https://energiefoerderung.zh.ch/internet/microsites/energie/de/geld-bekommen/uebersicht-foerderprogramm-energie.html\">Website</a></p>\n"
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Wappen Fördergelder Wärmepumpen Kanton Zürich",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/1kdO9Ty7GBE3Nghmim4eT7/0f08acb6f8af9e1026a54f8ccba07fd9/Z__rich.png"
                        }
                    }
                ],
                "title": "Wärmepumpen Fördergelder im Kanton Zürich",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Von Fördergeldern im Kanton Zürich profitieren. Kantonales Förderprogramm ✔️ Grundbeitrag plus leistungsabhängige Beiträge ✔️ Hier informieren ✔",
                "metaKeywords": "fördergelder kanton zürich"
            },
            {
                "type": "page",
                "id": "7um2Rv8IZUeQFMOhmIqczb",
                "updatedAt": "2022-08-08T11:40:59.154Z",
                "name": "Das Gebäudeprogramm",
                "slug": "foerdergelder/das-gebaeudeprogramm",
                "hideInProduction": false,
                "components": [
                    {
                        "type": "knowledgeArticle",
                        "id": "xhK8X9omD1LH7zsUsOoU7",
                        "updatedAt": "2023-04-20T16:07:25.281Z",
                        "title": "Das Gebäudeprogramm von Bund und Kantonen",
                        "keyword": "Das Gebäudeprogramm",
                        "articleType": "Infoartikel",
                        "description": "Bund und Kantone wollen mit dem Gebäudeprogramm den Energieverbrauch im Schweizer Gebäudepark erheblich reduzieren und den CO2-Ausstoss senken.",
                        "content": [
                            {
                                "type": "infoarticle",
                                "id": "55ItQEj5FCrWXsG17a9coL",
                                "updatedAt": "2020-03-27T08:29:33.516Z",
                                "title": "Das Gebäudeprogramm",
                                "text": "<h3>Energetisch sanieren bewirkt viel</h3>\n<p>Eine bessere Dämmung kann den Wärmebedarf eines Gebäudes um mehr als die Hälfte reduzieren, ein Umstieg auf erneuerbare Energien beim Heizen die CO2-Emmissionen fast auf null senken. Mit dem Gebäudeprogramm wollen Bund und Kantone den Energieverbrauch und den CO2-Ausstoss des Schweizer Gebäudeparks reduzieren.</p>\n<p>Dank des Gebäudeprogramms erhalten Sie Fördergelder für energetisch wirksame bauliche Massnahmen. Informieren Sie sich jetzt, für welche Massnahmen Sie Förderbeiträge erhalten und wie Sie ein Gesuch stellen können. Beginnen Sie mit einem GEAK Plus, dem Gebäudeenergieausweis der Kantone plus Empfehlungen.</p>\n",
                                "gallery": [
                                    {
                                        "type": "youTubeLink",
                                        "id": "4cYGEJRTZwlmkvOKt2LebY",
                                        "updatedAt": "2020-03-24T11:02:08.417Z",
                                        "title": "Das Gebäudeprogramm – Erklärvideo",
                                        "link": "https://www.youtube.com/watch?time_continue=322&v=yinV4U9KkU0&feature=emb_logo",
                                        "description": "<p>Das Video erklärt, wie Gesuchstellende vorgehen müssen, um Fördergeld aus dem Gebäudeprogramm zu erhalten.</p>\n",
                                        "videoId": "yinV4U9KkU0",
                                        "start": null
                                    }
                                ]
                            }
                        ],
                        "topic": {
                            "type": "link",
                            "id": "3P9prSaMlzdADoC8f1gPT7",
                            "updatedAt": "2021-02-11T13:38:26.956Z",
                            "label": "Fördergelder",
                            "page": {
                                "slug": "foerdergelder"
                            }
                        },
                        "previewImage": {
                            "description": "Logo farbig Das Gebäudeprogramm von Bund und Kantonen",
                            "contentType": "image/png",
                            "url": "//images.ctfassets.net/wbm1kpreb3m8/4A5QO38evSKGhvepvW9bTy/ecfa6f07d8be64826db1482416fa775b/02---Format---Blogartikel-Einleitung-_-Bild.png"
                        }
                    }
                ],
                "title": "Das Gebäudeprogramm: Fördergelder",
                "sideNavigation": {
                    "type": "navigation",
                    "id": "4QkWsUYTmKRxHloUODPsji",
                    "updatedAt": "2023-09-28T15:36:01.698Z",
                    "name": "Seitennavigation Fördergelder Wärmepumpe",
                    "links": [
                        {
                            "type": "link",
                            "id": "6imdAPCpzzJin32ITPF7Tv",
                            "updatedAt": "2020-03-18T15:08:38.076Z",
                            "label": "Schweizweite Förderung",
                            "page": {
                                "slug": "schweizweite-foerderung"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4OW4jSV0Vk2H7QMmCcj8gc",
                            "updatedAt": "2021-09-03T09:57:55.973Z",
                            "label": "Das Gebäudeprogramm",
                            "page": {
                                "slug": "das-gebaeudeprogramm"
                            }
                        },
                        {
                            "type": "link",
                            "id": "cIQKSgk11rayF9abVfi4k",
                            "updatedAt": "2020-03-27T08:01:44.514Z",
                            "label": "Häufig gestellte Fragen - FAQ",
                            "page": {
                                "slug": "haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
                            "updatedAt": "2020-03-13T15:09:44.207Z",
                            "label": "Fördergelder Kanton Aargau",
                            "page": {
                                "slug": "kanton-aargau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "6jyOwojHZQnjIMizqwQnXQ",
                            "updatedAt": "2020-03-23T09:16:33.860Z",
                            "label": "Fördergelder Kanton Basel-Landschaft",
                            "page": {
                                "slug": "kanton-basel-landschaft"
                            }
                        },
                        {
                            "type": "link",
                            "id": "539aNbJu1biigQT5Unbhd2",
                            "updatedAt": "2020-03-23T10:22:43.087Z",
                            "label": "Fördergelder Kanton Basel-Stadt",
                            "page": {
                                "slug": "kanton-basel-stadt"
                            }
                        },
                        {
                            "type": "link",
                            "id": "2jA1lO1AcRz7oZ2sSFx9vg",
                            "updatedAt": "2020-03-23T10:27:44.702Z",
                            "label": "Fördergelder Kanton Bern",
                            "page": {
                                "slug": "kanton-bern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "223GuzpRXRVSaAYk6BFtSq",
                            "updatedAt": "2020-03-23T11:00:32.709Z",
                            "label": "Fördergelder Kanton Luzern",
                            "page": {
                                "slug": "kanton-luzern"
                            }
                        },
                        {
                            "type": "link",
                            "id": "1t30LGgkuILdIGApksWHvv",
                            "updatedAt": "2023-09-28T15:35:50.064Z",
                            "label": "Fördergelder Kanton Schaffhausen",
                            "page": {
                                "slug": "foerdergelder-kanton-schaffhausen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "11DxxtCQGVZdWGHeKaLoUq",
                            "updatedAt": "2020-03-24T09:20:23.945Z",
                            "label": "Fördergelder Kanton Solothurn",
                            "page": {
                                "slug": "kanton-solothurn"
                            }
                        },
                        {
                            "type": "link",
                            "id": "3mbZseYEYjKC7VQPe24HQS",
                            "updatedAt": "2023-04-20T15:20:55.004Z",
                            "label": "Fördergelder Kanton St. Gallen",
                            "page": {
                                "slug": "foerdergelder-kanton-st-gallen"
                            }
                        },
                        {
                            "type": "link",
                            "id": "5RPB8qgJ9nc7aoIwoFxmul",
                            "updatedAt": "2023-04-20T15:21:32.792Z",
                            "label": "Fördergelder Kanton Thurgau",
                            "page": {
                                "slug": "kanton-thurgau"
                            }
                        },
                        {
                            "type": "link",
                            "id": "4mUIIT6zOW58vyTDWkKUGx",
                            "updatedAt": "2020-03-24T10:00:31.055Z",
                            "label": "Fördergelder Kanton Zug",
                            "page": {
                                "slug": "kanton-zug"
                            }
                        },
                        {
                            "type": "link",
                            "id": "59vmXjjI3AnPXmVsC4kKBz",
                            "updatedAt": "2020-03-24T10:03:26.817Z",
                            "label": "Fördergelder Kanton Zürich",
                            "page": {
                                "slug": "kanton-zuerich"
                            }
                        }
                    ]
                },
                "homepage": false,
                "metaDescription": "Das Gebäudeprogramm von Bund und Kantonen: Liegenschaft sanieren ✔️ Energieverbrauch senken ✔️ Fördergelder kassieren ✔️ Jetzt informieren ✔️",
                "metaKeywords": "gebäudeprogramm"
            }
        ],
        "isVertical": true
    },
    "content": "<p>Als Wärmepumpen-Spezialisten haben wir langjährige Erfahrung mit sämtlichen Kontaktstellen. <strong>Profitieren Sie von unserer Komplett-Lösung und<a href=\"https://www.heizungsmacher.ch/heizungsersatz/\"> überlassen Sie uns das Beantragen der Fördergelder für Ihren Heizungsersatz</a></strong>.</p>\n<p>Der <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ersatz von fossilen Heizsystemen</a> durch solche mit erneuerbaren Energiequellen wird in der Schweiz aktiv gefördert. Bund, Kantone, Gemeinden sowie einige Energieversorger stellen Gelder von teilweise <strong>über 10’000 Franken</strong> zur Verfügung. <strong><a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpen</a> als energieeffiziente Heizalternative werden besonders gefördert.</strong></p>\n<p>Abhängig von der bestehenden Heizung, dem neuen Heizsystem, dessen Leistung und Effizienz, Ihrem Wohnsitz und weiteren Bedingungen, kann der Betrag variieren.</p>\n<p>Damit Sie <strong>auf dem neuesten Stand bleiben</strong>, erhalten Sie <strong>im folgenden Abschnitt den Zugang zum Thema Fördergelder der jeweiligen Kantone</strong>.</p>\n"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
